.panel[data-v-106c86ed] {
  width: 100%;
  height: 122px;
  border-radius: 10px;
  color: #fff;
  padding: 21px 23px;
}
.panel .count[data-v-106c86ed] {
    font-size: 32px;
    font-weight: 600;
}
.panel .text[data-v-106c86ed] {
    margin-top: 19px;
}
.panel .text span[data-v-106c86ed] {
      white-space: nowrap;
}
.panel .text .svg-icon[data-v-106c86ed] {
      float: right;
      width: 35px;
      height: 35px;
      color: #fff;
      font-size: 39px;
}
.box[data-v-106c86ed] {
  border-radius: 5px;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 17px;
}
.box .title[data-v-106c86ed] {
    color: #1890ff;
    font-size: 24px;
}
.row-container[data-v-106c86ed] {
  width: 100%;
  margin-top: 80px;
}
.row-container .el-col[data-v-106c86ed] {
    text-align: center;
}
.row-container .el-col .number[data-v-106c86ed] {
      font-size: 28px;
      color: #1890ff;
}
.row-container .el-col .text[data-v-106c86ed] {
      margin-top: 10px;
}
@media (max-width: 768px) {
.panel[data-v-106c86ed] {
    height: 60px;
    border-radius: 10px;
    color: #fff;
    padding: 0;
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
}
.panel .count[data-v-106c86ed] {
      font-size: 14px;
      font-weight: 600;
}
.panel .text[data-v-106c86ed] {
      margin-top: 10px;
}
.panel .text .svg-icon[data-v-106c86ed] {
        display: none;
}
}
